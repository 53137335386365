const tags = [
  "none",
  "AU1-#FFFFFF",
  "AU2-#FFFFFF",
  "AU3-#FFFFFF",
  "AU4-#FFFFFF",
  "AU8-#FFFFFF",
  "FN16-#FFFFFF",
  "FP1-#FFFFFF",
  "FP2-#FFFFFF",
  "FP4-#FFFFFF",
  "FP8-#FFFFFF",
  "FR4-#FFFFFF",
  "FS1-#FFFFFF",
  "FS4-#FFFFFF",
  "FS16-#FFFFFF",
  "FT6-#FFFFFF",
  "FV5-#FFFFFF",
  "IH5-#FFFFFF",
  "KA1-#FFFFFF",
  "KA3-#FFFFFF",
  "KCP2-#FFFFFF",
  "KE1-#FFFFFF",
  "KG1-#FFFFFF",
  "KG2-#FFFFFF",
  "KP1-#FFFFFF",
  "KP2-#FFFFFF",
  "KP3-#FFFFFF",
  "KP4-#FFFFFF",
  "KP5-#FFFFFF",
  "KP6-#FFFFFF",
  "KP7-#FFFFFF",
  "KP8-#FFFFFF",
  "KP9-#FFFFFF",
  "KT32-#FFFFFF",
  "KU1-#FFFFFF",
  "KU4-#FFFFFF",
  "KU8-#FFFFFF",
  "KX4-#FFFFFF",
  "01-#444444",
  "02-#444444",
  "03-#444444",
  "04-#444444",
  "05-#444444",
  "06-#444444",
  "07-#444444",
  "08-#444444",
  "09-#444444",
  "10-#444444",
  "A1-#AB3225",
  "A2-#AB3225",
  "A3-#AB3225",
  "A4-#AB3225",
  "A5-#AB3225",
  "A6-#AB3225",
  "A7-#AB3225",
  "A8-#AB3225",
  "A9-#AB3225",
  "A10-#AB3225",
  "AGA1-#219166",
  "AGA2-#219166",
  "AGA3-#219166",
  "AL1-#622010",
  "AL2-#622010",
  "AL3-#622010",
  "AL4-#622010",
  "AL5-#622010",
  "AL6-#622010",
  "B1-#DF5539",
  "B2-#DF5539",
  "B3-#DF5539",
  "B4-#DF5539",
  "B5-#DF5539",
  "B6-#DF5539",
  "BBMA-#2FF3D9",
  "C1-#E36A3D",
  "C2-#E36A3D",
  "C3-#E36A3D",
  "C4-#E36A3D",
  "C6-#E36A3D",
  "C7-#E36A3D",
  "C8-#E36A3D",
  "C9-#E36A3D",
  "D1-#51E2E2",
  "D2-#51E2E2",
  "DOG1-#8A7660",
  "DOG2-#8A7660",
  "DOG3-#8A7660",
  "E1-#EFA238",
  "E2-#EFA238",
  "E3-#EFA238",
  "E4-#EFA238",
  "E5-#EFA238",
  "E6-#EFA238",
  "E7-#EFA238",
  "E8-#EFA238",
  "F1-#FAE547",
  "F2-#FAE547",
  "F3-#FAE547",
  "G1-#C7CA33",
  "G2-#C7CA33",
  "G3-#C7CA33",
  "G4-#C7CA33",
  "G5-#C7CA33",
  "G6-#C7CA33",
  "G7-#C7CA33",
  "G8-#C7CA33",
  "G9-#C7CA33",
  "H1-#7DAD39",
  "H2-#7DAD39",
  "H3-#7DAD39",
  "H4-#7DAD39",
  "H5-#7DAD39",
  "H6-#7DAD39",
  "HB1-#A5ACB2",
  "HB2-#A5ACB2",
  "IND1-#040D69",
  "IND2-#040D69",
  "J1-#578F34",
  "J2-#578F34",
  "J3-#578F34",
  "J4-#578F34",
  "J5-#578F34",
  "J6-#578F34",
  "K1-#397E3F",
  "K2-#397E3F",
  "K3-#397E3F",
  "L1-#1EA644",
  "L2-#1EA644",
  "L3-#1EA644",
  "L4-#1EA644",
  "L5-#1EA644",
  "L6-#1EA644",
  "L7-#1EA644",
  "L8-#1EA644",
  "L9-#1EA644",
  "L10-#1EA644",
  "L11-#1EA644",
  "L12-#1EA644",
  "M1-#225651",
  "M2-#225651",
  "M3-#225651",
  "M4-#225651",
  "M5-#225651",
  "M6-#225651",
  "N1-#124864",
  "N2-#124864",
  "N3-#124864",
  "NC-#000000",
  "OAK1-#0000FE",
  "OAK2-#0000FE",
  "OAK3-#0000FE",
  "P1-#1E69A2",
  "P2-#1E69A2",
  "P3-#1E69A2",
  "P4-#1E69A2",
  "P5-#1E69A2",
  "P6-#1E69A2",
  "P7-#1E69A2",
  "P8-#1E69A2",
  "P9-#1E69A2",
  "Q1-#257CBF",
  "Q2-#257CBF",
  "Q3-#257CBF",
  "Q4-#257CBF",
  "Q5-#257CBF",
  "Q6-#257CBF",
  "Q7-#257CBF",
  "Q8-#257CBF",
  "Q9-#257CBF",
  "Q10-#257CBF",
  "S1-#76AFDC",
  "S2-#76AFDC",
  "S3-#76AFDC",
  "S4-#76AFDC",
  "S5-#76AFDC",
  "S6-#76AFDC",
  "SS1-#00FE7F",
  "SS2-#00FE7F",
  "SS3-#00FE7F",
  "SUM1-#700905",
  "SUM2-#700905",
  "T1-#B3CAE9",
  "T2-#B3CAE9",
  "T3-#B3CAE9",
  "U1-#5D6873",
  "U2-#5D6873",
  "U3-#5D6873",
  "U4-#5D6873",
  "U5-#5D6873",
  "U6-#5D6873",
  "V1-#9112FF",
  "V2-#9112FF",
  "V3-#9112FF",
  "V4-#9112FF",
  "V5-#9112FF",
  "V6-#9112FF",
  "V7-#9112FF",
  "V8-#9112FF",
  "WE-#000000",
  "X1-#B188A8",
  "X2-#B188A8",
  "X3-#B188A8",
  "X4-#B188A8",
  "X5-#B188A8",
  "X6-#B188A8"
];

export { tags };
