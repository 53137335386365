<template>
  <CRow>
    <CCol sm="3">
      <CInput
        v-model="form.exposure"
        label="Product id"
        placeholder="Product id"
      />
    </CCol>
  </CRow>
</template>

<script>
import Button from "@/components/root/Button";
import ModalPresetsItem from "./ModalPresetsItem";

export default {
  name: "modal-presets",

  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },

    isShow: {
      type: Boolean,
      default: true
    }
  },

  components: {
    Button,
    ModalPresetsItem
  },

  data: () => ({
    form: [
      {
        reference: null,
        image: null,
        // preset: null,
        free: false,
        order: 0
      }
    ]
  }),

  created() {
    console.log("this.form", this.data);

    this.form = [...this.data];
  },

  watch: {
    data(value) {
      this.form = [...value];
    }
  },

  methods: {
    handleChangeFile(value, index) {
      this.form[index] = value;
      this.form[index].type = "edit";

      this.$emit("change", this.form);
    },

    handleAddNewPreset() {
      this.form.push({
        reference: null,
        image: null,
        // preset: null,
        free: false,
        order: 0
      });

      this.$emit("change", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-presets {
  &__button {
    margin: 0 auto;

    margin-top: 1.5rem;
  }
}
</style>
