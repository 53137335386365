<template>
  <Modal
    :show="isShow"
    :title="title"
    :errors="errors"
    full
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <!-- <pre>{{ form }}</pre> -->
    <div class="presetio-modals-modificated" ref="formContainer">
      <CRow>
        <CCol sm="6">
          <CInput v-model="form[`name`]" :label="`Name`" :placeholder="`Name`" />
        </CCol>

        <!-- <CCol sm="6">
          <CInput
            v-model="form[`description`]"
            label="Description"
            placeholder="Description"
          />
        </CCol>-->

        <!-- <CRow v-for="lang in languages" :key="`description_${lang}`">
        <CCol sm="12">
          <CInput
            v-model="form[`description_${lang}`]"
            :label="`Description  ${lang}`"
            :placeholder="`Description ${lang}`"
          />
        </CCol>
        </CRow>-->

        <CCol sm="6">
          <CInput v-model="form.product_id" label="Product id" placeholder="Product id" />
        </CCol>

        <CCol sm="6">
          <CInput
            v-model="form.count_download"
            label="Count download"
            placeholder="Count download"
          />
        </CCol>

        <CCol sm="6">
          <CInput v-model="form.created_at" label="Created At" placeholder="Created At" />
        </CCol>

        <CCol class="user-info__value" sm="3">
          <!-- <AppSlect v-model="form.filters" :options="filterOptions" /> -->

          <Select
            v-model="form.filter"
            label="Filter"
            placeholder="Select item"
            :options="tagOptions"
          />
        </CCol>

        <CCol sm="3">
          <CInput
            v-model="form.filter_setting"
            label="Filter Setting"
            placeholder="Filter Setting"
          />
        </CCol>

        <CCol sm="2">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Show"
            :checked="form.show"
            @update:checked="value => (form.show = value)"
          />
        </CCol>

        <CCol sm="2">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Free"
            :checked="form.free"
            @update:checked="value => (form.free = value)"
          />
        </CCol>

        <CCol sm="2">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Premium"
            :checked="form.premium"
            @update:checked="value => (form.premium = value)"
          />
        </CCol>
      </CRow>

      <div class="presetio-modals-modificated__settings">
        <h4 class="presetio-modals-modificated__settings-title">Settings</h4>
        <CRow>
          <CCol sm="3" v-for="item of settingsItems" :key="item">
            <CInput
              :value="form.settings[item]"
              :label="item.slice(0, 1).toUpperCase() + item.slice(1)"
              :placeholder="item.slice(0, 1).toUpperCase() + item.slice(1)"
              @input="value => handleChangeSetting({ value, key: item })"
            />
          </CCol>
        </CRow>
      </div>

      <!-- <ModalSettings
        :isShow="isShow"
        :data="form.settings"
        @change="value => (form.settings = value)"
      />-->

      <CRow>
        <CCol sm="12">
          <ModalPresets
            :isShow="isShow"
            :data="form.presets"
            @change="value => (form.presets = value)"
            @delete="deletePreset"
          />
        </CCol>
      </CRow>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";

import MixinsScreenModal from "@/mixins/screenModal";

import ModalPresets from "./ModalPresets";
import ModalSettings from "./ModalSettings";

import { tags } from "../data";

import FileLoader from "@/components/root/FileLoader";

import Select from "@/components/root/Select";

import MultiselectServerSearch from "@/components/root/MultiselectServerSearch";

export default {
  name: "NumberModalsModificated",

  mixins: [MixinsScreenModal],

  components: {
    ModalPresets,
    FileLoader,
    Select,

    MultiselectServerSearch
  },

  data: () => ({
    form: {
      name: null,
      description: null,
      product_id: null,
      count_download: 0,
      presets: [],
      show: true,
      free: false,
      premium: false,
      created_at: null,
      // preset: null,
      settings: {},
      filter: "none",
      filter_setting: 12
    },

    settingsItems: [
      "exposure",
      "contrast",
      "adjust",
      "sharpen",
      "clarity",
      "saturation",
      "highlights",
      "shadows",
      "temperature",
      "tint",
      "skin-tone",
      "vignette",
      "grain",
      "fade",
      "shadows-tint",
      "highlights-tint"
    ]

    // tagOptions: [
    //   { value: "Premium", label: "Premium" },
    //   { value: "A6", label: "A6" },
    //   { value: "KP9", label: "KP9" },
    //   { value: "F2", label: "F2" }
    // ],

    // languages: ["en", "ru", "fr", "es", "de", "it"]
  }),

  computed: {
    title() {
      if (this.isShow) {
        return this.data
          ? "Edit Filteroom collection"
          : "Add Filteroom collection";
      }
    },

    tagOptions() {
      let res = [];

      for (let item of tags) {
        let items = item.split("-");
        res.push({ value: item, label: items[0] });
      }

      return res;
    },

    createdAt() {
      // return this.form[`created_at`];
      // return moment(this.form[`created_at`])
      //   .subtract("hours", 3)
      //   .format("YYYY-MM-DD hh:mm:ss");
    }
  },

  // created() {
  //   // for (let lang of this.languages) {
  //   //   this.form[`name_${lang}`] = null;
  //   // }

  //   for (let lang of this.languages) {
  //     this.form[`description_${lang}`] = null;
  //   }
  // },

  methods: {
    handleChangeFile(file) {
      console.log("handleChangeFile");

      this.form.preset = {
        type: "upload",
        file,
        url: URL.createObjectURL(file)
      };

      // this.form[key].image = URL.createObjectURL(file);

      this.$emit("change", this.form);
    },

    deletePreset(index) {
      console.log(index);

      if (this.form.presets[index] && this.form.presets[index].id)
        this.handlerDeletePreset({
          id: this.form.presets[index].id,
          cb: () => {
            this.form.presets.splice(index, 1);
          }
        });
      else this.form.presets.splice(index, 1);
    },

    handleChangeSetting({ value, key }) {
      console.log({ value, key });

      this.form.settings = { ...this.form.settings, [key]: value };

      // if (key == "clarity" || key == "vignette")
      //   this.form.settings[key] = value;
      // else this.form.settings[key] = Number.parseInt(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.presetio-modals-modificated {
  &__preset {
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    border: 1px solid #768192;
  }

  &__settings {
    margin: 2rem 0;
    padding: 10px;

    border: 1px solid #768192;
  }

  &__settings-title {
    margin-bottom: 1rem;
  }
}
</style>
